import axios from 'axios'
import AuthToken from '@/auth/auth-token'

class Party {
  constructor () {
    const base = axios.create({
      baseURL: process.env.VUE_APP_CUSTOMER_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json'
      }
    })
    this.party = base
  }

  async getStores (limit = 200) {
    await AuthToken.setAuth(this.party, {
      audience: process.env.VUE_APP_CUSTOMER_AUDIENCE
    })
    return this.party({
      url: '/party/search_parties',
      method: 'POST',
      params: { limit },
      data: { party_types: ['STORE'] }
    })
  }

  async getAdGroups (limit = 200) {
    await AuthToken.setAuth(this.party, {
      audience: process.env.VUE_APP_CUSTOMER_AUDIENCE
    })
    return this.party({
      url: '/party/search_parties',
      method: 'POST',
      params: { limit },
      data: { party_types: ['AD_GROUP'] }
    })
  }

  async getCategoryManagers () {
    await AuthToken.setAuth(this.party, {
      audience: process.env.VUE_APP_CUSTOMER_AUDIENCE
    })
    return this.party({
      url: '/party/search_parties',
      method: 'POST',
      data: { party_types: ['CATEGORY_MANAGER'] }
    })
  }
}

export default new Party()