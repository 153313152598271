import BaseApi from './base'
class Features extends BaseApi {
  constructor () {
    super()
  }

  async getAdPageFeatures (adId, adPage, params) {
    return this.auth({
      url: `/ad_features/${adId}/${adPage}`,
      params: params,
      method: 'GET'
    })
  }

  async getAdFeatureById (adFeatureId) {
    return this.auth({
      url: `/ad_feature/${adFeatureId}`,
      method: 'GET'
    })
  }

  async createAdFeature (adFeature) {
    return this.auth({
      url: '/ad_feature',
      method: 'POST',
      data: adFeature
    })
  }

  async updateAdFeature (adFeatureId, adFeature) {
    return this.auth({
      url: `/ad_feature/${adFeatureId}`,
      method: 'PUT',
      data: adFeature
    })
  }

  async deleteAdFeature (adFeatureId) {
    return this.auth({
      url: `/ad_feature/${adFeatureId}`,
      method: 'DELETE'
    })
  }

  async getFeaturePositions(ad_id, params){
    return this.auth({
      url: `/ad_feature/feature_positions/${ad_id}`,
      method:'GET',
      params:params
    })
  }

  async nonPromoLookup(params){
    return this.auth({
      url: `/ad_feature/non-promo-items/`,
      method:'GET',
      params:params
    })
  }
}

export default new Features()