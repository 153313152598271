import BaseApi from './base'
class Ads extends BaseApi{
  constructor () {
    super()
  }
  
  handleSuccess (response) {
    return response
  }

  async getAdList (adPartyId, adDate, limit, offset) {
    const params = {
      ad_party_id: adPartyId,
      limit: limit,
      offset: offset
    }
    
    if (adDate) params.ad_date = adDate

    return await this.auth({
      url: '/ads',
      method: 'GET',
      params: params
    })
  }

  async getAdById (adId) {
    return await this.auth({
      url: `/ad/${adId}`,
      method: 'GET'
    })
  }

  async getAdNotes (adId) {
    return await this.auth({
      url: `/ad_notes/${adId}`,
      method: 'GET'
    })
  }

  async saveAdNote (adNote) {
    return await this.auth({
      url: '/ad_note',
      method: 'POST',
      data:adNote
    })
  }

  async createAd (ad) {
    return await this.auth({
      url: '/ad',
      method: 'POST',
      data: ad
    })
  }

  async updateAd (adId, ad) {
    return await this.auth({
      url: `/ad/${adId}`,
      method: 'PUT',
      data: ad
    })
  }

  async deleteAd(adId){
    return await this.auth({
      url: `/ad/${adId}`,
      method: 'DELETE',
    })
  }

  async generateAdPlanFiles(adData) {
    return await this.auth({
      url: '/ad_plan_files',
      method: 'POST',
      data:adData
    })
  }

  async generateAdPlanner (adId, data){
    return await this.auth({
      url: `/ad_planner/${adId}`,
      method: 'POST',
      data:data
    })
  }

  async generateCSV (ad_id) {
    return await this.auth({
      url: `/ad_planner/csv/${ad_id}`,
      method: 'GET'
    })
  }

  async shuttle(id, body){
    return await this.auth({
      url: `/ad/shuttle/${id}`,
      method:'POST',
      data:body
    })
  }

  async clone(id, body){
    return await this.auth({
      url:`/ad/clone/${id}`,
      method:'POST',
      data:body
    })
  }

  async checkAd(id){
    return await this.auth({
      url:`/ad/check_ad/${id}`,
      method:'GET'
    })
  }

  async shuttleHistory(id){
    return await this.auth({
      url:`/ad/shuttle_history/${id}`,
      method:'GET'
    })
  }

  async countItems(id){
    return await this.auth({
      url:`/ad/ad_item_count/${id}`,
      method:'GET'
    })
  }
  async search(id, params){
    // params = JSON.stringify(params)
    return await this.auth({
      url:`/ad_planner/search/${id}`,
      method:'GET',
      params : params
    }
    ) 
  }

  async getShuttleAdGroups(ad_id, curr_ad_group_id) {
    return await this.auth({
      url: `/ad/ad_groups_to_shuttle/${ad_id}/${curr_ad_group_id}`,
      method:'GET',
    })
  }

  async getShipperComponents(ad_id, feature_id, params) {
    return await this.auth({
      url: `/ad_planner/shipper_components/${ad_id}/${feature_id}`,
      method: 'GET',
      params: params
    })
  }
}

export default new Ads()